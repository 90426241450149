.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media ( prefers-reduced-motion : no-preference ) {

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container form {
  width: 400px !important;
}

.formLogin {
  align-items: center;
  display: block;
  padding: 15%;
}

.formWallet {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  padding: 1.25rem;
  display: flex;
}

.image {
  width: 50%;
}
